import '../styles/faq.scss';

import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import DownArrow from '../images/DownArrow.jpeg';
import UpArrow from '../images/UpArrow.jpeg';

function FaqPage() {

  const onClick_section = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> onClick_section ' + event.target.name );
    if ( !showSections[index] ) {
      setShowSections( { ...showSections, [index]: true } );
    }
    else {
      setShowSections( { ...showSections, [index]: false } );
    }
  };

  const onClick = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> onClick ' + event.target.name, showResults[index] );
    if ( !showResults[index] ) {
      setShowResults( { ...showResults, [index]: true } );
    }
    else {
      setShowResults( { ...showResults, [index]: false } );
    }
  };

  const [ showSections, setShowSections ] = React.useState( { 1: true } );
  const [ showResults, setShowResults ] = React.useState( {} );

  return (
    <Layout header={'menu'}>
      <SEO
        title="Get answers related to Life &amp; Home Insurance Queries "
        description="Our FAQ section will help you get answers to the most frequently asked questions, related to Life and Home Insurance. Visit now!"
        canonicalUrl='https://www.getmybubble.io/faq'
        robots={ { 1:'index', 2: 'follow' } }
      />

      <div id="faq" className="wrapper">
        <div className="flexColumn carrierCommonPage">
          <div className="company-info-container container-fluid">
            <div className="row">
              <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                  <h1 className="carrier-plan-heading section70" style={{ lineHeight: '1.55', textAlign: 'center' }}>
                                    Frequently Asked Questions
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="container faq-margin-bottom common-container-space">
            <div className="row">
              <div className="col-lg-12">
                {/*About Home Insurance*/}

                <div className="row">
                  <div className="col-lg-12">
                    <div className="faqFlex bg-white-border">
                      <div className="faqLeftContainer faq-left-container">
                        <div className="col-sm-12" onClick={( e ) => onClick_section( e, 1 )}>
                          <div className="align-faq-que position-relative">
                            <div className="faq-section-headings">
                                                                Why Bubble
                            </div>

                            <img
                              src={showSections[1] ? UpArrow : DownArrow}
                              className="faq-dd m-0" name="1" alt="Bubble Life Insurance"/></div>
                        </div>

                        <div className="col-12 both-space-padding">
                          <div className="row">
                            {showSections[1] && <>
                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 3 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> Does Bubble have any
                                                                                                representatives that I can talk to or is everything only online?
                                          </div><img src={showResults[3] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0" name="7"
                                            alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[3] && `You can get most of what
                                                                                            you need online but we also have specialists to help you through
                                                                                            anything you need.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 4 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> Why should I buy my insurance
                                                                                                from Bubble? </div><img src={showResults[4] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0"
                                            name="15" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[4] && `Bubble was founded with a
                                                                                            mission to make insurance simple, transparent, and approachable.
                                                                                            Everything we do revolves around these principles for making our
                                                                                            customers' lives easy. `}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 5 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> Will Bubble be around till my
                                                                                                insurance expires? </div><img src={showResults[5] ? UpArrow : DownArrow}
                                            className="faq-dd faq-dd-width m-0" name="18" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[5] && `Our term life insurance is
                                                                                            issued by listed carriers like The Savings Bank Mutual Life Insurance
                                                                                            Company of Massachusetts, Stillwater Insurance Group, Pacific Life
                                                                                            Insurance Company, Symetra Life Insurance Company, and more who have
                                                                                            been around for more than 70+ years individually.3. What's different
                                                                                            about Bubble?`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 6 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> What's different about
                                                                                                Bubble? </div><img src={showResults[6] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0"
                                            name="19" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[6] && `Only with Bubble you can
                                                                                            buy life insurance along with home insurance with easy, quick, and
                                                                                            uncomplicated processes. We will not only help you every step of the way
                                                                                            to make sure you get your ideal home and life insurance policies but
                                                                                            also help you with any support you need post-purchase.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 7 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> What kinds of insurance can I
                                                                                                buy from Bubble? </div><img src={showResults[7] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0"
                                            name="20" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[7] && `At the moment, you can buy
                                                                                            home and life insurance together or separately from us. You can be
                                                                                            assured to get the ideal policy to fit your needs and budget.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 8 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> How easy is it to buy
                                                                                                insurance from Bubble? </div><img src={showResults[8] ? UpArrow : DownArrow}
                                            className="faq-dd faq-dd-width m-0" name="27" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[8] && `Quite easy! A
                                                                                            questionnaire to fill, a few details to share, and a policy to pick.
                                                                                            That's the gist of how easy it is to get your insurance from Bubble.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 9 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> How easy is it to buy
                                                                                                insurance from Bubble? </div><img src={showResults[9] ? UpArrow : DownArrow}
                                            className="faq-dd faq-dd-width m-0" name="28" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[9] && `Quite easy! A
                                                                                            questionnaire to fill, a few details to share, and a policy to pick.
                                                                                            That's the gist of how easy it is to get your insurance from Bubble.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 10 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> I was rejected by another
                                                                                                insurance company for home insurance. Will I still be eligible to
                                                                                                get one from Bubble? </div><img src={showResults[10] ? UpArrow : DownArrow}
                                            className="faq-dd faq-dd-width m-0" name="29" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[10] && `We offer policies to most
                                                                                            home types and circumstances. Even if you were given a thumbs down by a
                                                                                            different carrier, Bubble could be your best bet.
                                                                                        `}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 11 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> How do I buy a home and life
                                                                                                insurance policy from Bubble? </div><img src={showResults[11] ? UpArrow : DownArrow}
                                            className="faq-dd faq-dd-width m-0" name="44" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[11] && `It's easy! Simply begin
                                                                                            your journey by getting a quote on our website. We'll take you through
                                                                                            the process and get you your ideal policy.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 12 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> What are the benefits of
                                                                                                buying a home insurance policy from Bubble? </div><img src={showResults[12] ? UpArrow : DownArrow}
                                            className="faq-dd faq-dd-width m-0" name="45" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[12] && `Bubble eliminates lengthy
                                                                                            and complicated processes, you can get your home insurance in minutes,
                                                                                            and you can also get deductible options and discounts to save money.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 13 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> What are the benefits of
                                                                                                buying a life insurance policy from Bubble? </div><img src={showResults[13] ? UpArrow : DownArrow}
                                            className="faq-dd faq-dd-width m-0" name="53" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[13] && `Customizable: Start with
                                                                                            the coverage you need now and adjust it at a later time
                                                                                            Smart: Save time and money by locking in your premium for decades
                                                                                            Sensible: Flexible coverage options that adapt throughout your life`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*End of Home Insurance */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default FaqPage;
